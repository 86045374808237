import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core'
import { RouterModule } from '@angular/router'
import { CommonModule } from '@angular/common'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'

import { TranslateModule } from '@ngx-translate/core'

import { IonicModule } from '@ionic/angular'

import { GlobalModule } from '@mediacoach-ui-library/global'

import {
  AggregationSelectorComponent,
  ButtonComponent,
  CalendarComponent,
  CollapseComponent,
  DropdownComponent,
  EditMetricsBlockComponent,
  FieldDiagramComponent as McMFieldDiagramComponent,
  HeaderComponent,
  HeaderPlayerComponent,
  HeatMapBlockComponent,
  HeatMapComponent as McMHeatMapComponent,
  ListWrapperComponent,
  LoaderComponent,
  MatchCardBlockComponent,
  MatchItemComponent,
  MenuComponent,
  MetricListComponent,
  ModalComponent,
  PlayerComparisonComponent,
  PlayerSelectionComponent,
  SegmentedControlComponent,
  SignInOutComponent,
  SpiderChartBlockComponent,
  SpiderChartComponent,
  StatTableComponent,
  TableComponent,
  TeamBadgeComponent,
  TeamSelectionComponent,
  TeamStateComponent,
  TeamTrendComponent,
  TitleComponent,
  WarningModalComponent
} from './components'

import { SafePipe } from './pipes/safe.pipe'
import { LoadingPipe } from './pipes/loading.pipe'
import { FirstLetterUppercasePipe } from './pipes/first-letter-uppercase.pipe'

import {
  AnalyticsClickEventDirective,
  AutofocusDirective,
  DataAttributeDirective,
  HeaderCollapseDirective,
  LoadingDirective,
  LongPressDirective,
  OnClickOutsideDirective,
  TemplateDirective,
  VarDirective
} from './directives'

import { CompetitionSelectorComponent } from './components/competition-selector/competition-selector.component'
import { ExcludePlayerPipe } from './pipes/exclude-player.pipe'
import { GridTemplateDirective } from './components/table/directives/grid-template.directive'
import { TeamPlayersGridComponent } from './components/team-players-grid/team-players-grid.component'
import { CommonListComponent } from '@shared/components/match-common-list/common-list.component'
import { SectionContainerComponent } from '@shared/components/section-container/section-container.component'
import { KitSelectorComponent } from '@shared/components/kit-selector/kit-selector.component'
import { ContextMenuModule } from '@shared/components/context-menu/context-menu.module'
import { CalendarNavigatorDirective } from '@shared/components/calendar/directives/calendar-navigator.directive'
import { InvalidValueHandlerPipe } from './pipes/invalid-value-handler.pipe'
import { PositionPipe } from '@shared/pipes/position.pipe'
import { VideoContainerComponent } from '@shared/components/video-container/video-container.component'
import { VideoMarkersComponent } from '@shared/components/video-container/components/video-markers/video-markers.component'
import { VideoComponent } from '@shared/components/video-container/components/video/video.component'
import { AttachMarkersDirective } from '@shared/components/video-container/directives/attach-markers.directive'
import { TooltipHelperDirective } from '@shared/components/video-container/directives/tooltip-helper.directive'
import { VideoUnavailableComponent } from '@shared/components/video-container/components/video-unavailable/video-unavailable.component'
import { VideoResizeHandlerDirective } from '@shared/components/video-container/directives/video-resize-handler.directive'
import { VideoUserActiveDirective } from '@shared/components/video-container/directives/video-user-active.directive'
import {
  ApplicationMenuModule,
  AvatarModule,
  CalendarModule,
  DirectivesModule,
  FileAvatarModule,
  PipesModule,
  TooltipModule
} from '@mediacoach/ui'
import { MultipleTranslatePipe } from '@shared/pipes/multiple-translate.pipe'
import { DemarcationPipe } from '@shared/pipes/demarcation.pipe'
import { RxFor } from '@rx-angular/template/for'

export const MODULES = [
  GlobalModule,
  IonicModule,
  TranslateModule,
  CommonModule,
  RouterModule,
  FormsModule,
  ReactiveFormsModule,
  ContextMenuModule,
  CalendarModule,
  DirectivesModule,
  TooltipModule,
  RxFor,
  ApplicationMenuModule,
  AvatarModule,
  FileAvatarModule,
  PipesModule
]

export const COMPONENTS = [
  HeaderComponent,
  MenuComponent,
  ButtonComponent,
  SegmentedControlComponent,
  TeamTrendComponent,
  MatchItemComponent,
  TeamBadgeComponent,
  MetricListComponent,
  TitleComponent,
  StatTableComponent,
  ListWrapperComponent,
  LoaderComponent,
  CalendarComponent,
  ModalComponent,
  DropdownComponent,
  SpiderChartComponent,
  TeamSelectionComponent,
  CompetitionSelectorComponent,
  TeamStateComponent,
  McMFieldDiagramComponent,
  McMHeatMapComponent,
  HeaderPlayerComponent,
  CollapseComponent,
  PlayerSelectionComponent,
  PlayerComparisonComponent,
  TableComponent,
  TeamPlayersGridComponent,
  CommonListComponent,
  SectionContainerComponent,
  KitSelectorComponent,
  AggregationSelectorComponent,
  VideoContainerComponent,
  VideoMarkersComponent,
  VideoComponent,
  VideoUnavailableComponent,
  WarningModalComponent,
  HeatMapBlockComponent,
  EditMetricsBlockComponent,
  SpiderChartBlockComponent,
  MatchCardBlockComponent,
  SignInOutComponent
]

export const DIRECTIVES = [
  LoadingDirective,
  TemplateDirective,
  AutofocusDirective,
  OnClickOutsideDirective,
  HeaderCollapseDirective,
  AnalyticsClickEventDirective,
  VarDirective,
  FirstLetterUppercasePipe,
  DataAttributeDirective,
  AttachMarkersDirective,
  TooltipHelperDirective,
  LongPressDirective,
  VideoResizeHandlerDirective,
  VideoUserActiveDirective
]
export const PIPES = [
  SafePipe,
  LoadingPipe,
  InvalidValueHandlerPipe,
  PositionPipe,
  MultipleTranslatePipe,
  DemarcationPipe
]

@NgModule({
  declarations: [
    ...COMPONENTS,
    ...PIPES,
    ...DIRECTIVES,
    ExcludePlayerPipe,
    GridTemplateDirective,
    CalendarNavigatorDirective
  ],
  imports: [...MODULES],
  exports: [...MODULES, ...COMPONENTS, ...PIPES, ...DIRECTIVES],
  providers: [...PIPES],
  schemas: [NO_ERRORS_SCHEMA]
})
export class SharedModule {}
