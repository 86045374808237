import {Injectable} from '@angular/core'
import {Actions, createEffect, ofType} from '@ngrx/effects'
import {concatLatestFrom} from '@ngrx/operators'
import {Observable, throwError} from 'rxjs'
import {catchError, filter, map, switchMap, tap,} from 'rxjs/operators'
import {Action, Store} from '@ngrx/store'
import {HttpResponse} from '@angular/common/http'
import {
  closeProfileView,
  fetchUserProfile,
  saveUserAvatar,
  saveUserProfile, saveUserProfileAndCloseView,
  setProfile,
  setUserApplications,
  setUserProfile,
  setUserProfileLoading,
  updateUserCompetitionTeam,
  updateUserCompetitionTeamSuccess
} from '@core/store/actions/profile.actions'
import {UserProfile} from '@core/services/auth/auth.models'
import {signOut} from '@core/store/actions/auth.actions'
import {catchRequestError, HttpStatusCode} from '@mediacoach/ui'
import {ProfileApi} from '@core/requests/api/profile/profile.api'
import {parseProfile} from '@core/services/auth/auth.utils'
import {selectProfile} from '@core/store/selectors/user.selectors'
import {RouterEventsService} from '@core/services/router-events/router-events.service'
import {fetchTeamSummary} from '@pages/team/store/actions/team.actions'
import {fetchCurrentSeason} from '@core/store/actions/season.actions'
import {connectSocket} from '@core/store/actions/socket.actions'
import {ToastService} from "@core/services/toast/toast.service";
import {encodeToBase64} from "@core/utils/image.utils";

@Injectable()
export class ProfileEffects {
  fetchProfile$ = createEffect(() =>
    this._actions$.pipe(
      ofType(fetchUserProfile),
      switchMap(() =>
        this._api.fetchUserProfile().pipe(
          map((res: HttpResponse<UserProfile>) => {
            if (res.status === HttpStatusCode.NoContent) {
              return signOut()
            }
            const profile = {...parseProfile(res.body as UserProfile)}
            this._routerEventsService.permissions = profile.permissions
            return setProfile({profile})
          }),
          tap(() => this._store.dispatch(fetchCurrentSeason())),
          catchError((err) => {
            return throwError(() => err)
          })
        )
      )
    )
  )

  setProfile$ = createEffect(() =>
    this._actions$.pipe(
      ofType(setProfile),
      switchMap(({profile}) => {
        return [
          setUserProfile({profile}),
          setUserApplications({
            applications: profile ? profile.profiles : [],
          }),
          connectSocket()
        ]
      })
    )
  )

  saveProfile$: Observable<Action> = createEffect(() =>
    this._actions$.pipe(
      ofType(saveUserProfile),
      switchMap(({profile: {avatar, ...rest}}) =>
        this._api.saveUserProfile(rest).pipe(
          tap(() => this._notifyProfileUpdateSuccess()),
          map(() => ({avatar, ...rest })),
          map((profile) => setUserProfile({profile})),
          catchError(err => {
            console.error(err)
            this._notifyRequestError()
            return throwError(() => err)
          })
        )
      )
    )
  )

  saveAndCloseProfile$: Observable<Action> = createEffect(() =>
    this._actions$.pipe(
      ofType(saveUserProfileAndCloseView),
      switchMap(({profile: {avatar, ...rest}}) =>
        this._api.saveUserProfile(rest).pipe(
          map(() => ({avatar, ...rest })),
          catchError(err => {
            console.error(err)
            this._notifyRequestError()
            return throwError(() => err)
          })
        )
      ),
      tap(() => this._notifyProfileUpdateSuccess()),
      switchMap((profile) => [
        setUserProfile({profile}),
        closeProfileView()
      ])
    )
  )

  updateUserCompetitionTeam$: Observable<Action> = createEffect(() =>
    this._actions$.pipe(
      ofType(updateUserCompetitionTeam),
      filter(({competitionId, teamId}) => !!competitionId && !!teamId),
      concatLatestFrom(() => this._store.select(selectProfile)),
      map(([{competitionId, teamId}, profile]) => ({
        ...profile,
        favouriteCompetitionId: competitionId,
        favouriteTeamId: teamId,
        favourites: {...profile.favourites, competitionId, teamId},
      })),
      switchMap(({avatar, ...profile}: UserProfile) =>
        this._api.saveUserProfile({...profile}).pipe(
          map(() => ({...profile, avatar})),
          tap(() => this._store.dispatch(setUserProfile({profile}))),
          catchError((err) => {
            this._notifyRequestError()
            this._store.dispatch(setUserProfileLoading({loading: false}))
            return throwError(() => err)
          })
        )
      ),
      switchMap((profile) => [
        fetchTeamSummary(),
        updateUserCompetitionTeamSuccess({profile}),
        setUserProfileLoading({loading: false})
      ])
    )
  )

  saveAvatar$: Observable<Action> = createEffect(() =>
    this._actions$.pipe(
      ofType(saveUserAvatar),
      map(({profile: {photoPath, ...rest}}) => {
        return {
          encodedImage: photoPath,
          profile: rest
        }
      }),
      switchMap(({encodedImage, profile}) =>
        this._api.saveUserAvatar({encodedImage}).pipe(
          map(() => ({
            ...profile,
            avatar: encodedImage
          })),
          catchError(err => {
            setUserProfile({profile})
            console.error(err)
            this._notifyRequestError('MTR_PROFILE_AVATAR_ERROR')
            return throwError(() => err)
          })
        ),
      ),
      map(profile => saveUserProfileAndCloseView({profile}))
    )
  )

  constructor(
    private readonly _actions$: Actions,
    private readonly _api: ProfileApi,
    private readonly _routerEventsService: RouterEventsService,
    private readonly _store: Store,
    private readonly _toast: ToastService
  ) {
  }


  private _notifyProfileUpdateSuccess() {
    this._toast.create({
      cssClass: 'success-toast',
      message: 'MTR_PROFILE_UPDATE_SUCCESS'
    })
  }


  private _notifyRequestError(translationKey: string = 'MTR_PROFILE_UPDATE_ERROR'){
    this._toast.create({
      cssClass: 'error-toast',
      message: translationKey
    })
  }
}
